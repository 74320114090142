<template>
    <Form :isModal="isModal" :data="data" @submit="submit" @showModal="$emit('showModal')"/>
</template>


<script>
import Form from '@/components/auth/Form'
import { reactive } from '@vue/reactivity'
import api from '@/services/auth/auth.service.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getValidationError, clearValidationError } from '@/helpers/objects'
import {onMounted} from "vue";

export default {
    props: ['isModal', 'doNotRedirect'],
    components: {
        Form
    },
    setup(props, { emit }) {
        const router = useRouter()
        const route = useRoute()
    
        const store = useStore()

        const data = reactive({
            step: 'form',
            title: 'Login',
            inputs: [
                { label: 'Email', placeholder: 'Your email', value: route.query.email ?? '', type: 'text', key: 'email', error: '' },
                { label: 'Password', placeholder: 'Password', value: '', type: 'password', key: 'password', error: '' },
            ],
            link: {
                title: 'FORGOT PASSWORD',
                href: '/forgot-password'
            },
            btn: {
                title: 'LOGIN',
                type: 'primary',
                icon: 'fas fa-sign-in-alt',
                loading: false,
            }
        })

        async function submit(payload) {
            clearValidationError(data.inputs)
            const result = await api.login(payload)

            data.btn.loading = false

            if(result.errors || result.error) {
                if(result.errors) getValidationError(result.errors, data.inputs)
                return
            }

            localStorage.setItem('user', JSON.stringify(result.user))

            localStorage.setItem('token', result.token)

            store.commit('main/setField', {
                field: 'user', value: result.user,
            })

            store.commit('main/setField', {
                field: 'isAuth', value: true,
            })

            if(!props.isModal) {
                if(route.query.email) {
                    router.push('/create')
                }else {

                    router.push('/my-estimates')
                }
            } 
            else emit('logined')
        }
        onMounted(() => {
          if (!props.doNotRedirect && localStorage.getItem('user') && localStorage.getItem('token')) {
            router.push('/my-estimates')
          }
        })


        return {
            data,
            submit,
        }
    },

}
</script>

<style scoped>
::v-deep .btn__icon {
  display: flex !important;
}
</style>
