<template>
  <Form :data="{step: 'success', title: ''}">
    <template #success>

      <div class="no-auth" v-if="activeStep === -1">
        <inline-preloader message="Checking your details, please wait..." />
      </div>
      <div class="no-auth" v-else-if="activeStep === 0">
        <h3 class="tw-mb-4">Your Details</h3>

        <InputGroup
            :data="{ label: 'First Name',
              type: 'text',
              required: true,
              error: !data.first_name ? 'Name is required' : '',
              value: data.first_name,
              }"
              v-model="data.first_name"></InputGroup>

        <InputGroup
            :data="{ label: 'Last Name',
              type: 'text',
              required: true,
              error: !data.last_name ? 'Last Name is required' : '',
              value: data.last_name,
              }"
            v-model="data.last_name"></InputGroup>

        <InputGroup
            :data="{ label: 'Email',
              type: 'text',
              required: true,
              error: !data.email ? 'Email is required' : '',
              value: data.email,
              }"
            v-model="data.email"></InputGroup>

        <InputGroup
            :data="{ label: 'Mobile',
              type: 'text',
              required: true,
              error: !data.mobile ? 'Mobile is required' : '',
              value: data.mobile,
              }"
            v-model="data.mobile"></InputGroup>

        <Button :data="{
      title: 'Continue',
      type: 'primary',
      icon: 'fas fa-check',
    }" class="link_dotted" @click="submitUserDetails().then(result => activeStep = result)"/>

      </div>
      <div class="no-auth" v-else-if="activeStep === 1">
        <LoginForm :is-modal="true" @logined="processLogin" :do-not-redirect="true" />

      </div>
      <div class="no-auth" v-else-if="activeStep === 2">
        <h3 class="tw-mb-4">Job Details</h3>
        <!--CU-86cx1vv1q Kiruba, can you please replace address fields below with
         standard address input component linked to the data like fields below-->
        <FormAddress
            class="block__item" 
            :states="states"
            :data="addressData"
            @updAddress="updAddress($event)"
            @updStates="states = $event"
            @onClick="submitJobDetails"
            :showAddressError="showAddressError"
            @hideError="showAddressError = false"
            :hideUpdateEmail="true"
        />

        <!-- <InputGroup
            :data="{ label: 'Address',
              type: 'text',
              required: true,
              error: !data.address ? 'Address is required' : '',
              value: data.address,
              }"
            v-model="data.address"></InputGroup>

        <InputGroup
            :data="{ label: 'Suburb',
              type: 'text',
              required: true,
              error: !data.suburb ? 'Suburb is required' : '',
              value: data.suburb,
              }"
            v-model="data.suburb"></InputGroup>

        <InputGroup
            :data="{ label: 'State',
              type: 'text',
              required: true,
              error: '',
              value: data.state,
              }"
            v-model="data.state"></InputGroup>

        <InputGroup
            :data="{ label: 'Postcode',
              type: 'text',
              required: true,
              error: '',
              value: data.postcode,
              }"
            v-model="data.postcode"></InputGroup> -->
          <Button :data="{
        title: 'Continue',
        type: 'primary',
        icon: 'fas fa-check',
      }" class="link_dotted" @click="submitJobDetails"/>
      </div>
      <div class="no-auth" v-else-if="activeStep === 3">
        <DealerSelectionLogin @navigateToDealerLogin="navigateToDealerLogin" :dealers="dealers"/>
      </div>
      <div class="no-auth" v-else-if="activeStep === 4">

        <inline-preloader message="Taking you to the estimate..." />
      </div>
      <div class="no-auth" v-else>
        <p class="no-auth__desc">This is not DIY. Please proceed to get an estimate for measured and installed insect
          and security screens and doors.</p>
        <Button :data="btnOptions" class="no-auth__btn"/>
        <p class="no-auth__desc">Note: The estimate price may vary after site inspection.</p>
        <div class="no-auth__log no-auth__desc">Already have an Account?
          <Button :data="btnLogin" class="link_dotted"/>
          <!--<router-link to="/login" class="link_dotted">LOG IN</router-link>--></div>
      </div>
    </template>
  </Form>
</template>

<script>
import Form from '@/components/auth/Form.vue'
import Button from '@/components/form/Button.vue'
import InputGroup from "@/components/form/InputGroup.vue";
import {reactive, ref} from '@vue/reactivity'
import {useRoute} from "vue-router";
import { useStore } from 'vuex'
import router from "@/router";
import inlinePreloader from "@/components/item-wizard/components/estimate/specification/InlinePreloader.vue";
import api from "@/services/auth/auth.service"
import LoginForm from '@/components/auth/LoginForm'
import FormAddress from '@/components/item-wizard/components/form/FormAddress'
import { nextTick, watch } from 'vue';
import DealerSelectionLogin from './DealerSelectionLogin.vue';

export default ({
  components: {
    Form,
    Button,
    inlinePreloader,
    InputGroup,
    LoginForm,
    FormAddress,
    DealerSelectionLogin,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const states = ref(null)
    const showAddressError = ref(false)

    const btnOptions = reactive({
      title: 'PROCEED TO ESTIMATE',
      type: 'primary',
      href: '/create',
      icon: 'fas fa-check',
    })

    const data = ref({
        first_name: route.query.first_name? decodeURIComponent(route.query.first_name): null,
        last_name:  route.query.last_name? decodeURIComponent(route.query.last_name) : null,
        email:  route.query.email? decodeURIComponent(route.query.email) : null,
        mobile:  route.query.phone? decodeURIComponent(route.query.phone) : null,
        address: route.query.address? decodeURIComponent(route.query.address) : null,
        suburb: route.query.suburb? decodeURIComponent(route.query.suburb) : null,
        state:  route.query.state? decodeURIComponent(route.query.state) : null,
        postcode:  route.query.postcode? decodeURIComponent(route.query.postcode) : null,
    })

    const activeStep = ref(-1)

    const dealers = ref([])

    function navigateToDealerLogin(dealer) {
      const params = new URLSearchParams({
        email: data.value.email,
        first_name: data.value.first_name,
        last_name: data.value.last_name,
        phone: data.value.mobile,
      })

      location.href = `${dealer.url}/enter?${params.toString()}`
    }

    async function initialise() {
      // Object.keys(data.value).forEach((k) => {
      //   const param = route.query[k]
      //   if (param){
      //     data.value[k] = decodeURIComponent(param)
      //   }
      // })

      let step = activeStep.value + 1
      if (data.value.first_name && data.value.last_name && data.value.email && data.value.mobile)
      {
        step = await submitUserDetails()
      }

      if(step == 2){
        if (data.value.address && data.value.suburb && data.value.state && data.value.postcode){
          await submitJobDetails()
        }
      }

      activeStep.value = step
    }

    initialise()

    async function submitUserDetails(){
      if (!data.value.first_name || !data.value.last_name || !data.value.email || !data.value.mobile) {
        activeStep.value = 0
        return 0
      }

      const result = await api.checkUserDetails(data.value, (localStorage.getItem('user') && localStorage.getItem('token')) ? null : 'noAuth' )
      // For New user; login with newly created account
      if (result.action == 'login'){

        localStorage.setItem('user', JSON.stringify(result.user))

        localStorage.setItem('token', result.token)

        store.commit('main/setField', {
          field: 'user', value: result.user,
        })

        store.commit('main/setField', {
          field: 'isAuth', value: true,
        })

        return 2
      }else if (result.action == 'authorise'){
        router.push(`/login?email=${encodeURIComponent(data.value.email)}&existinguser=yes`)
      }else if (result.action == 'job-details'){
        return await submitJobDetails()
      }else if (result.action === 'dealers' && result.dealers.length === 0) {
        // Already has an account redirect to login.
        router.push(`/login?email=${encodeURIComponent(data.value.email)}&existinguser=yes`)
      }else if (result.action === 'dealers' && result.dealers.length >= 1) {
        dealers.value = result.dealers
        return 3
      }

    }

    async function submitJobDetails(){
      console.log('submit job details')

      
      console.log('submit user details')
      showAddressError.value = false
      const form = JSON.parse(JSON.stringify(data.value))
      //set value id
      const stateInput = addressData.inputs.find(el => el.key == 'state')
      if(form.state) form.state_id = stateInput.value_id

      if(!form.address || !form.postcode || !form.state || !form.suburb){
        showAddressError.value = !form.address ? 'Address field is required': !form.state ? 'State field is required': !form.suburb ? 'Suburb field is required': !form.postcode ? 'Postcode field is required': false
        // addressData.btn.loading = false
        activeStep.value = 2
        return 2
      }

      // if (!data.value.address || !data.value.suburb || !data.value.state || !data.value.postcode) {
      //   activeStep.value = 2
      //   return 2
      // }

      // activeStep.value = 3
      const result = await api.iniitaliseEstimate(form)
      localStorage.setItem('customerOrderId', result.container_id)
      router.push('/create')
      //Login, create estimate and redirect

    }

    async function processLogin(){
      console.log("Logged in.....")
    }

    const btnLogin = reactive({
      title: 'LOG IN',
      type: 'primary',
      href: '/login',
      icon: 'fas fa-sign-out ',
    })

    const addressData = reactive({
        inputs: [
            { label: 'Address', component: 'address-autocomplete', placeholder: 'Enter street address', value: route.query.address, key: 'address', type: 'text', style: 'width: 100%;' ,required:true},
            { label: 'State', component: 'address-state', placeholder: 'Enter state', value: '', value_id: route.query.state,  key: 'state', type: 'text', style: 'width: 100%;',required:true },
            { label: 'Suburb', placeholder: 'Enter suburb', value: route.query.suburb,  key: 'suburb', type: 'text',style: 'width: calc(65% - 12px); margin-right: 12px;',required:true },
            { label: 'Postcode', placeholder: 'Enter postcode', value: route.query.postcode,  key: 'postcode', type: 'text', style: 'width: 35%;' ,required:true},
        ],
    })

    watch(states, (val) => {
        if(val){
            updAddress({
            'address': data.value.address,
            'state': data.value.state,
            'suburb': data.value.suburb,
            'postcode': data.value.postcode
        })
        }
    }, {once: false, immediate: true})

    updAddress({
        'address': data.value.address,
        'state': data.value.state,
        'suburb': data.value.suburb,
        'postcode': data.value.postcode
    })

    async function updAddress(address) {
      console.log('upd address')
      showAddressError.value = false
        for(const key in address) {
            const value = address[key]
            const input = addressData.inputs.find(el => el.key == key)
            if(input && key !== 'state') input.value = value
            else if(input) {
                let matchStateName = states.value?.find(el => el.name == address[key])
                if(!matchStateName) {
                    matchStateName = states.value.find(el => el.state_id == address[key])
                }

                if(matchStateName) {
                    input.value = null
                    input.value_id = null
                    nextTick(() => {
                        input.value = matchStateName.name
                        input.value_id = matchStateName.state_id
                    })
                }else {
                    if(key === 'state') {
                        input.value = null
                        input.value_id = null
                    }
                }
            }
            if(value === '' || value) data.value[key] = value
        }
      }

    return {
      dealers,
      navigateToDealerLogin,
      states,
      showAddressError,
      updAddress,
      addressData,
      btnOptions,
      btnLogin,
      activeStep,
      data,

      submitUserDetails,
      submitJobDetails
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.auth-form__success) {
  width: 415px;
  margin: 0;
}

.no-auth {
  &__desc {
    margin-bottom: 14px;
    text-align: left;
    font-size: 14px;
    line-height: 140%;
  }

  &__btn {
    margin-bottom: 7px;
    padding: 10px !important;
  }

  &__log {
    text-align: left;
    margin-bottom: 0;

    a {
      margin-left: 50px;
    }
  }
}

.no-auth__log.no-auth__desc {
  display: inline-grid !important;
  font-size: 18px !important;
  width: 100%;
  font-weight: bold !important;
}

.link_dotted {
  max-width: fit-content;
  margin: 12px 0 0 !important;
  background: var(--PrimaryButtonColour);
  color: var(--PrimaryButtonTextColour);
  padding: 10px;
}

@media (max-width: 768px) {
  :deep(.auth-form__success) {
    width: 100%;
  }
}
</style>
