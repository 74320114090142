<script setup>
const props = defineProps({
    dealers: {
        default: [],
    }
})

const emit = defineEmits(['navigateToDealerLogin'])

</script>
<template>
    <div>
        <h2 class="tw-mb-2">Select Dealer</h2>
        <h5 class="tw-text-base">It looks like you have one or more accounts. Please select the dealer you would like to log in with to proceed with your estimate.</h5>
    
        <div class="tw-grid tw-grid-cols-2 tw-gap-4 mt-4 tw-items-start">
            <div v-for="dealer in dealers" :key="dealer.id" class="tw-cursor-pointer hover:tw-opacity-60 tw-transition-all tw-shadow-md tw-border-2 tw-border-gray-300 tw-p-4 tw-flex tw-items-center tw-h-full" @click="emit('navigateToDealerLogin', dealer)">
                <img class="tw-w-full tw-object-cover" :src="dealer.logo" alt="" style="max-height: 100px;"/>
            </div>
        
        </div>
    
    </div>
</template>