<template>
    <div class="auth-page">
        <div class="auth-form">
            <div class="auth-form__title" v-if="data.title">{{ data.title }}</div>
            <h3 v-if="$route.query.existinguser === 'yes'" class="tw-text-base mb-4" style="max-width: 300px;">It looks like you already have an account with our system. Please enter your details to log in and proceed with your estimate.</h3>
            <template v-if="data.step == 'form'">

                <InputGroup class="auth-form__input"
                    v-for="(item, index) in data.inputs"
                    :data="item" :key="`input-${index}`"
                    v-model="item.value"
                    :style="[index == data.inputs.length - 1 ? 'margin-bottom: 0;' : '']"/>

                <div class="auth-form__link" v-if="data.link">
                    <span class="link_dotted" @click="goTo(data.link.href)">{{ data.link.title }}</span>
                </div>

            </template>

            <div class="auth-form__success" :class="data.successStepClass" v-if="data.step == 'success'">
                <slot  name="success"></slot>
            </div>

            <Button class="auth-form__btn" :data="data.btn" @click="submit" v-if="data.btn"/>
        </div>

    </div>
</template>

<script>
import InputGroup from '@/components/form/InputGroup.vue'
import Button from '@/components/form/Button.vue'
import { getValueFromObject } from '@/helpers/objects'
import { useRoute, useRouter } from 'vue-router'

export default({
    props: ['data', 'isModal'],
    components: {
        InputGroup,
        Button,
    },
    setup(props, {emit}) {
        const router = useRouter()
        const route = useRoute()

        function submit() {
            const data = getValueFromObject(props.data.inputs)
            emit('submit', data)
        }

        function goTo(link) {
            if(!props.isModal) router.push(link)
            else window.open(link)
        }

        return {
            submit,
            goTo
        }
    }
})
</script>


<style lang="scss" scoped>
.auth-page {
  height:100vh;
  min-height: auto!important;
    //height: 100%;
    width: 100%;
    //min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth-form {
   // width: 522px;
   // min-height: 358px;
   // padding: 55px 36px 21px;
    display: flex;
    flex-direction: column;
   // align-items: center;
    background: #fff;

    min-height: auto!important;
    width: auto!important;
    padding: 40px!important;
    align-items: flex-start!important;
    min-width: 375px;
    &__title {
        font-weight: bold;
       // font-size: 24px;
        line-height: 100%;
        margin-bottom: 20px;
        font-weight: Sansation;
      text-align: left;
      font-size: 24px!important;
    }
    &__input {
        margin-bottom: 17px;
    }
    &__link {
      margin-top: 10px!important;
      //  margin-top: 14px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        a {
            color: var(--SecondaryButtonColour);
        }
      & .link_dotted{
        font-size: 12px;
      }
    }
    &__btn {
       // margin-top: 31px;
      margin-top: 12px!important;
      padding: 10px!important;
    }
    &__success {
        font-size: 14px;
        line-height: 100%;
        min-height: 62px;
        margin: 24px 0 12px;
        width: 298px;
        display: inline-block;
        &.bold {
            font-weight: bold;
            font-size: 25px;
            line-height: 100%;
        }
    }
}
@media (max-width: 768px) {
  .auth-form {
    padding: 60px 40px !important;
  //  height: calc(100vh - 56px)!important;
    width: 100%;
    min-height: auto;
   // padding: 30px 20px;
    &__success {
      width: 100%;
    }
    &__title,.form-group.auth-form__input{
      margin-bottom : 10px;
    }
  }

  .auth-page {
    height: 100% !important;
  }

}

</style>
